import React from "react";

function Team() {
  return (
    <section className="service_section layout_padding">
      <div className="service_container">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>AN EXPERIENCED TEAM BRIDGING TRADFI AND DEFI</h2>
          </div>
          <div className="row">
            {/* Team members */}
            {teamMembers.map((member, index) => (
              <div key={index} className="col-md-6 col-lg-4">
                <div className="box">
                  <div className="img-box">
                    <img src={member.image} alt="" />
                  </div>
                  <div className="detail-box">
                    <div className="client_name">
                      <h5>{member.name}</h5>
                      <h6>{member.role}</h6>
                    </div>
                    <p>{member.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

const teamMembers = [
  {
    image: "images/s1.png",
    name: "Matteo Spinosa",
    role: "CEO-Founder",
    description:
      "18 years of institutional and TradFi in tier 1 banks. Finance Degree at Bocconi University (Milan).",
  },
  {
    image: "images/s2.png",
    name: "Eamonn Gashier",
    role: "Derivatives Consultant",
    description:
      "Following 15 years in Derivatives trading started career in DeFi: Founder of Block Scholes. Member of the UCL Centre for Blockchchain Technologies.",
  },
  {
    image: "images/s8.png",
    name: "Giacomo Sorbi",
    role: "Software Engineer",
    description:
      "Several years in engineering, with experiences involving Deutsche Bank, Meta, Booking.com, Yahoo! and BP.",
  },
  {
    image: "images/s9.png",
    name: "Mayowa Tudonu",
    role: "Software Engineer",
    description:
      "Diverse professional experience in various industries including E-commerce, Financial Technology, Online Education and Blockchain.",
  },
  {
    image: "images/s7.png",
    name: "Reza Jahankohan",
    role: "Software Engineer",
    description: "Experienced software engineer with over 10 years of expertise, including 5 in blockchain; strong background in backend development across  industries such as gaming, fintech, security, and AI.",
  },
  {
    image: "images/s6.png",
    name: "Raffaella Tenconi",
    role: "Regulatory and Policy Advisor",
    description:
      "Member of the Bretton Woods Committee in the Digital Finance working group, 21 years experience as economist. Msc Global Market Economics at The London School of Economics.",
  },
  {
    image: "images/s5.png",
    name: "Block Scholes",
    role: "Tech Consultant",
    description: "Institutional-grade analytics, data & research platform.",
  },
  {
    image: "images/s3.png",
    name: "Yoann Lewkowitz",
    role: "Legal Advisor",
    description:
      "A&O trained lawyer with 10yr of experience between hedge funds and fintech.",
  },
  {
    image: "images/s4.png",
    name: "Ataf Ahmed",
    role: "Advisor",
    description:
      "CEO of Graphene Investments, a Web3, blockchain & crypto focused company. Experience in private equity and asset management. PhD from the University of Cambridge.",
  },
];

export default Team;
